import React, { FC } from "react";
import { useStore } from "../../store";
import { SubLinkId } from "../../data";

import { Automation } from "./contents/HMI/Automation";
import { FlowKit } from "./contents/HMI/Flow-kit";
import { Cartridge } from "./contents/HMI/Cartridge";
import { Flow } from "./contents/HMI/Flow";
import { Pumps } from "./contents/HMI/Pumps";
import { Rating } from "./contents/HMI/Rating";
import { Dilution } from "./contents/HMI/Dilution";

const Content: FC = (): JSX.Element => {
    const { activeSubLink } = useStore();
    switch (activeSubLink.id) {
        case SubLinkId.automation:
            return <Automation />;
        case SubLinkId.cartridge:
            return <Cartridge />;
        case SubLinkId.flowKit:
            return <FlowKit />;
        case SubLinkId.flow:
            return <Flow />;
        case SubLinkId.pumps:
            return <Pumps />;
        case SubLinkId.rating:
            return <Rating />;
        case SubLinkId.dilution:
            return <Dilution />;
        default:
            return <></>;
    }
};

const SidebarContent: FC = (): JSX.Element => {
    return (
        <div className="c-sidebar__wrapper">
            <Content />
        </div>
    );
};

export default SidebarContent;
