import React from "react";
import { useStore } from "src/store";
import NavLink from "./NavLink.component";
import DropdownToggle from "src/hooks/Dropdown-toggle";

type NavLinkListProps = {};

const NavLinkList: React.FC<NavLinkListProps> = () => {
    const { links, dropdown, hasDropdown, hasNavLinks } = useStore();
    if (hasDropdown && hasNavLinks) {
        return (
            <nav
                className={`c-cytiva__nav-dp ${
                    dropdown ? "c-cytiva__nav-dropdown--active" : ""
                }`}
            >
                <ul
                    className={`c-cytiva__nav-list-dp ${
                        dropdown ? "c-cytiva__nav-list__dropdown--active" : ""
                    }`}
                >
                    {links.map((link) => (
                        <React.Fragment key={link.title}>
                            <NavLink link={link} />
                        </React.Fragment>
                    ))}
                </ul>
            </nav>
        );
    } else
        return (
            <nav className="c-cytiva__nav">
                <ul className="c-cytiva__nav-list">
                    {links.map((link) => (
                        <React.Fragment key={link.title}>
                            <NavLink link={link} />
                        </React.Fragment>
                    ))}
                </ul>
            </nav>
        );
};

export default NavLinkList;
