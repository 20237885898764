import React from "react";
import { useStore } from "src/store";
import { Spacer } from "../sidebar/contents/dynamic.components";
import { translationService } from "src/services/translation.service";

const TradeMark = ({}) => {
    const { closeOverlay, locale } = useStore();

    const textPart1 = translationService.translate("legal.text.1", locale);
    const textPart2 = translationService.translate("legal.text.2", locale);
    const textPart3 = translationService.translate("legal.text.3", locale);
    const textPart4 = translationService.translate("legal.text.4", locale);

    return (
        <>
            <div
                className="c-cytiva__trademark-section"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="c-cytiva__trademark-top">
                    <img
                        className="c-cytiva__trademark-top__logo"
                        src="android-chrome-192x192.png"
                        width="40"
                        alt="Cytiva logo"
                    />
                    <button
                        className="c-cytiva__trademark-top__close"
                        onClick={closeOverlay}
                    >
                        X
                    </button>
                </div>
                <p className="c-cytiva__trademark-text">
                    {textPart1}
                    <Spacer />
                    {textPart2}
                    <Spacer />
                    {textPart3}{" "}
                    <a
                        className="c-cytiva__trademark-link"
                        style={{ textDecoration: "none" }}
                        href="https://www.precisionnanosystems.com/contact-us"
                        target="_blank"
                        title="Contact us"
                    >
                        precisionnanosystems.com/contact
                    </a>
                    <Spacer />
                    {textPart4}
                </p>
            </div>
        </>
    );
};

export default TradeMark;
