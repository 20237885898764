import React from "react";
import { Title, Text, Spacer } from "../dynamic.components";
import { useStore } from "src/store";
import { translationService } from "src/services/translation.service";

export const Rating: React.FC = () => {
    const { activeSubLink, locale } = useStore();

    const mainText = translationService.translate(
        "sidebar.rating.main",
        locale
    );

    return (
        <>
            <Title title={activeSubLink.text[locale]} />

            <div className="c-sidebar__body">
                <Text text={mainText} />
                <Spacer />
            </div>
        </>
    );
};
