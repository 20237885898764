import React, { useEffect, useRef } from "react";
import { viewOption } from "../../constants";
import { useStore } from "../../store";

export const Viewer: React.FC<{ modelUid: string }> = ({
    modelUid,
}): JSX.Element => {
    const viewerIframeRef = useRef(null);
    const { setViewIsReady, setApiref } = useStore();

    const ViewerIframe = (
        <iframe
            // We feed the ref to the iframe component to get the underlying DOM object
            ref={viewerIframeRef}
            title="sketchfab-viewer"
            className="c-cytiva__webgl"
            allow="gyroscope;accelerometer;xr-spatial-tracking"
        />
    );

    const success = (api: any) => {
        setApiref(api);
        // api.ui
        api.start(() => {});
        api.load(() => {});
        api.addEventListener("viewerready", function () {
            api.pause();
            setViewIsReady(true);
            // API is ready to use
            // Insert your code here
            //   console.log("Viewer is ready");
        });
    };

    const error = () => {
        console.log("Viewer error");
    };

    const options = process.env.NODE_ENV === "development" ? {} : viewOption;

    useEffect(() => {
        // Initialize the viewer
        let client = new window.Sketchfab(viewerIframeRef.current);
        client.init(modelUid, {
            success: success,
            error: error,
            ...options,
            ui_stop: 0,
            ui_infos: 0,
        });
    }, []);

    return ViewerIframe;
};
