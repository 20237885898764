import React, { useEffect, useState } from "react";
import { Title, Text, Spacer, Subtitle } from "../dynamic.components";
import { useStore } from "src/store";
import { playHotspot } from "src/services/annotations";
import { SidebarVisibility } from "src/types/index.d";
import { translationService } from "src/services/translation.service";

export const Flow: React.FC = () => {
    const { activeSubLink, sidebarStatus, locale } = useStore();
    const [state, setState] = useState({
        flow: false,
        coriolis: false,
    });

    useEffect(() => {
        if (sidebarStatus === SidebarVisibility.show)
            setState({ flow: true, coriolis: false });
        return () => {};
    }, [sidebarStatus]);

    const title1 = translationService.translate(
        "sidebar.flowMeters.main.1.title",
        locale
    );
    const text1 = translationService.translate(
        "sidebar.flowMeters.main.1.text",
        locale
    );
    const title2 = translationService.translate(
        "sidebar.flowMeters.main.2.title",
        locale
    );
    const text2 = translationService.translate(
        "sidebar.flowMeters.main.2.text",
        locale
    );

    return (
        <>
            <Title title={activeSubLink.text[locale]} />
            <div className="c-sidebar__body">
                <Subtitle subtitle={title1} />
                <Spacer />
                <Text text={text1} />
                <Spacer x={2} />
                <Subtitle subtitle={title2} />
                <Spacer />
                <Text text={text2} />
                <Spacer x={3} />
                <button
                    style={{ maxWidth: "345px", width: "100%" }}
                    onClick={() => {
                        setState({ flow: true, coriolis: false });
                        activeSubLink.annotations &&
                            playHotspot(activeSubLink.annotations[0]);
                    }}
                    className={`button button--secondary button--xlarge button--fixwidth 
                    ${state.flow && "button--hover"}
                    `}
                >
                    {title1}
                </button>
                <Spacer x={1} />
                <button
                    style={{ maxWidth: "345px", width: "100%" }}
                    onClick={() => {
                        setState({ flow: false, coriolis: true });
                        activeSubLink.extras?.annotations &&
                            playHotspot(activeSubLink.extras?.annotations[0]);
                    }}
                    className={`button button--secondary button--xlarge button--fixwidth 
                ${state.coriolis && "button--hover"}
                  `}
                >
                    {title2}
                </button>
            </div>
        </>
    );
};
