export const translationsData = {
    translations: {
        title: {
            en: "Commercialsystem",
            zh: "商业系统",
            ja: "商業用システム",
        },
        single_nxgen_cartridge: {
            en: "Single-use NxGen™ cartridge",
            zh: "NxGen™一次性混合芯片",
            ja: "シングルユース NxGen™ カートリッジ",
        },
        single_nxgen_flowkit: {
            en: "Single-use NxGen™ flow kit",
            zh: "一次性NxGen™流路套件",
            ja: "シングルユース NxGen™ フローキット",
        },
        shared: {
            return: {
                en: "Return to 3D overview",
                zh: "返回 3D 概述",
                ja: "3Dの概要に戻る",
            },
            replay: {
                en: "Replay Animation",
                zh: "重播动画",
                ja: "アニメーションのリプレイ",
            },
        },
        sidebar: {
            singleUse: {
                en: "Single-use",
                zh: "一次性",
                ja: "シングルユース",
            },
            automation: {
                main: {
                    en: "The NanoAssemblr® commercial formulation system is powered by intuitive software that enables 21 CFR Part 11 compliance.",
                    zh: "NanoAssemblr® 商业配方系统采用直观的软件，符合美国《联邦法规汇编》(CFR) 的第21 篇第 11 部分的规定。",
                    ja: "NanoAssemblr® コマーシャルフォーミュレーション システムは、21 CFR Part 11 に準拠した直感的なソフトウェアを搭載しています。",
                },
                list: {
                    1: {
                        en: "Electronic batch records, including flow rate and pump speed logs",
                        zh: "电子批次记录，包括流量和泵速记录",
                        ja: "流量およびポンプ回転数ログを含む電子バッチ記録",
                    },
                    2: {
                        en: "Automated workflow for priming, calibrating, formulating, and diluting lipid nanoparticles",
                        zh: "启动、校准、配制和稀释脂质纳米颗粒的自动化工作流程",
                        ja: "脂質ナノ粒子のプライミング、キャリブレーション、調合、希釈の自動化ワークフロー",
                    },
                },
                comment: {
                    en: "Pending third party audit.",
                    zh: "等待第三方审核。",
                    ja: "第三者監査待ち。",
                },
            },
            cartridge: {
                main: {
                    en: "The single-use NxGen™ cartridge enables consistent mixing conditions, resulting in critical quality attributes (CQAs) that are maintained throughout scale-up to clinical development and commercial manufacturing.",
                    zh: "一次性 NxGen™ 试剂盒可实现一致的混合条件，从而在临床开发和商业生产的整个放大过程中保持关键质量属性 (CQA)。",
                    ja: "シングルユースのNxGen™カートリッジは、一貫した混合条件を可能にし、臨床開発や商業生産へのスケールアップを通して維持される重要な品質特性（CQAs）をもたらします。",
                },
                list: {
                    1: {
                        en: "Flow rates of up to 48 L/h",
                        zh: "流量高达 48 升/小时",
                        ja: "最大48 L/h の流量",
                    },
                    2: {
                        en: "Reduces the risk of cross-contamination between batches and drug products",
                        zh: "降低批次和药物产品之间的交叉污染风险",
                        ja: "ロット間および医薬品間の交差汚染のリスクを低減",
                    },
                },
            },
            flowKit: {
                main: {
                    en: "Single-use flow kit that includes the NxGen™ cartridge, pump heads, flow meters, and fluid path.",
                    zh: "一次性流路套件包括 NxGen™ 芯片、泵头、流量计和流路。",
                    ja: "NxGen™カートリッジ、ポンプヘッド、流量計、流路を含むシングルユースフローキットです。",
                },
                sub: {
                    en: "The flow kit can be installed and ready for formulation in less than 60 minutes, supporting efficient changeover between batches and products.",
                    zh: "该流动套件可在 60 分钟内完成安装并准备好配制，支持批次和产品之间的高效转换。",
                    ja: "フローキットは60分以内で設置でき、製剤の準備が整うことでバッチや製品間の効率的な切り替えをサポートします。",
                },
                list: {
                    1: {
                        en: "Single-use manufacturing flow kit for manufacturing under cGMP conditions",
                        zh: "用于在 cGMP 条件下生产的一次性生产流路套件",
                        ja: "cGMP条件下で製造するためのシングルユース製造フローキット",
                    },
                    2: {
                        en: "Available as a limited multi-use development flow kit for research use, including engineering batches",
                        zh: "可提供可多次使用的流路套件，用于研究和开发，包括工程批次",
                        ja: "エンジニアリングバッチを含む研究用の限定マルチユース開発フローキットとして入手可能",
                    },
                    3: {
                        en: "Composed of biocompatible and animal-derived ingredient free materials",
                        zh: "由生物相容性且不含动物源性成分的材料组成",
                        ja: "生体適合性と動物由来成分を含まない素材で構成",
                    },
                    4: {
                        en: "Gamma irradiated for bioburden reduction",
                        zh: "伽马射线照射以减少生物负载",
                        ja: "ガンマ線照射によるバイオバーデン低減",
                    },
                    5: {
                        en: "Extractables report available",
                        zh: "可提取物报告",
                        ja: "抽出物レポートあり",
                    },
                },
            },
            flowMeters: {
                main: {
                    1: {
                        title: {
                            en: "In-line meters",
                            zh: "在线流量计",
                            ja: "インラインメーター",
                        },
                        text: {
                            en: "In-line ultrasonic flow meters enable automated, real-time flow monitoring, resulting in consistent flow rates.",
                            zh: "在线超声波流量计可自动实时监测流量，从而保证恒定的流速。",
                            ja: "インライン超音波流量計は、自動化されたリアルタイムの流量監視を可能にし、安定した流量を実現します。",
                        },
                    },
                    2: {
                        title: {
                            en: "Coriolis meter",
                            zh: "科里奥利流量计",
                            ja: "コリオリメーター",
                        },
                        text: {
                            en: "An onboard Coriolis flow meter enables flow rate calibration of the in-line sensors before lipid nanoparticle formulation.",
                            zh: "机载科里奥利流量计能够在脂质纳米颗粒制备之前，对在线传感器进行流速校准。",
                            ja: "搭載されたコリオリメーターにより、脂質ナノ粒子製剤化前のインラインセンサーの流量校正が可能。",
                        },
                    },
                },
            },
            pumps: {
                main: {
                    en: "Low-pulsation, low-shear pumps ensure flow rate ratio accuracy and consistent mixing during formulation.",
                    zh: "低离心脉动、低剪切力的泵可确保制备过程中的流速比的准确性和混合一致性。",
                    ja: "低脈動、低剪断のポンプは、流量比の正確さと製剤中の一貫した混合を保証します。",
                },
            },
            rating: {
                main: {
                    en: "The NanoAssemblr® commercial formulation system is ATEX and IECEx rated to ensure safety when handling flammable solvents during lipid nanoparticle formulation.",
                    zh: "NanoAssemblr® commercial formulation system 通过 ATEX 和 IECEx 认证，可确保在脂质纳米颗粒制备过程中安全地处理易燃溶剂。",
                    ja: "NanoAssemblr® コマーシャルフォーミュレーション システムは、ATEXおよびIECEx に適合しており、脂質ナノ粒子製剤中に可燃性溶媒を取り扱う際の安全性を確保しています。",
                },
                comment: {
                    en: "Pending final certification",
                    zh: "等待三方认证",
                    ja: "最終認証申請中",
                },
            },
            dilution: {
                main: {
                    en: "Automated in-line dilution of lipid nanoparticles lowers the ethanol content immediately following formulation.",
                    zh: "脂质纳米颗粒的自动在线稀释可在制剂后立即降低乙醇含量。",
                    ja: "脂質ナノ粒子の自動インライン希釈は、製剤化直後のエタノール含量を低下させます。",
                },
                list: {
                    1: {
                        en: "Dilution ratios from 1:1 to 10:1",
                        zh: "稀释比例从 1:1 到 10:1不等",
                        ja: "希釈比は1:1から10:1まで",
                    },
                },
            },
        },
        help: {
            name: {
                en: "Help",
                zh: "帮助",
                ja: "ヘルプ",
            },
            top: {
                left: {
                    title: {
                        en: "Orbit around",
                        zh: "旋转",
                        ja: "周回軌道",
                    },
                    text: {
                        en: "Left click + drag or one finger drag (touch)",
                        zh: "左键单击 + 拖动或单指拖动（触摸）",
                        ja: "左クリック＋ドラッグまたは1本指ドラッグ（タッチ）",
                    },
                },
                center: {
                    title: {
                        en: "Zoom",
                        zh: "缩放",
                        ja: "ズーム",
                    },
                    text: {
                        en: "Double click on model or scroll anywhere or Pinch (touch)",
                        zh: "双击模型或在模型任意位置滚动鼠标滑轮或二指拨动（触摸）",
                        ja: "モデルをダブルクリック、または任意の場所をスクロール、またはピンチ（タッチ）",
                    },
                },
                right: {
                    title: {
                        en: "Pan",
                        zh: "拖移",
                        ja: "パン",
                    },
                    text: {
                        en: "Right click + drag or Two fingers drag (touch)",
                        zh: "右键单击 + 拖动或二指拖动（触摸）",
                        ja: "右クリック＋ドラッグまたは2本指ドラッグ（タッチ）",
                    },
                },
            },
            orbit: {
                title: {
                    en: "Orbit navigation",
                    zh: "旋转导航",
                    ja: "軌道ナビゲーション",
                },
                camera: {
                    title: {
                        en: "Move camera:",
                        zh: "移动镜头：",
                        ja: "カメラを動かす：",
                    },
                    text: {
                        en: "1-finger drag or Left Mouse Button",
                        zh: "单指拖动或鼠标左键",
                        ja: "1本指ドラッグまたは左マウスボタン",
                    },
                },
                pan: {
                    title: {
                        en: "Pan:",
                        zh: "拖移：",
                        ja: "パン：",
                    },
                    text: {
                        1: {
                            en: "2-finger drag or Right Mouse Button or",
                            zh: "二指拖动或鼠标右键或",
                            ja: "2本指ドラッグまたは右マウスボタンまたは",
                        },
                        2: {
                            en: "SHIFT",
                            zh: "SHIFT",
                            ja: "SHIFT",
                        },
                        3: {
                            en: "+ Left Mouse Button",
                            zh: "+ 鼠标左键",
                            ja: "＋ 左マウスボタン",
                        },
                    },
                },
                zoomObj: {
                    title: {
                        en: "Zoom on object:",
                        zh: "放大对象：",
                        ja: "オブジェクトにズーム：",
                    },
                    text: {
                        en: "Double-tap or Double-click on object",
                        zh: "双击对象",
                        ja: "オブジェクトをダブルタップまたはダブルクリック",
                    },
                },
                zoomOut: {
                    title: {
                        en: "Zoom out:",
                        zh: "缩小：",
                        ja: "ズームアウト：",
                    },
                    text: {
                        en: "Double-tap or Double-click on background",
                        zh: "双击背景",
                        ja: "背景をダブルタップまたはダブルクリック",
                    },
                },
                zoom: {
                    title: {
                        en: "Zoom:",
                        zh: "缩放：",
                        ja: "ズーム：",
                    },
                    text: {
                        1: {
                            en: "Pinch in/out or Mousewheel or",
                            zh: "二指向内/向外拨动或鼠标滚轮或",
                            ja: "ピンチイン/アウトまたはマウスホイール、または",
                        },
                        2: {
                            en: "CTRL",
                            zh: "CTRL",
                            ja: "CTRL",
                        },
                        3: {
                            en: "+ Left Mouse Button",
                            zh: "+ 鼠标左键",
                            ja: "＋ 左マウスボタン",
                        },
                    },
                },
            },
            rendering: {
                title: {
                    en: "Rendering",
                    zh: "渲染",
                    ja: "レンダリング",
                },
                list: {
                    1: {
                        en: "Lit:",
                        zh: "照亮：",
                        ja: "Lit:",
                    },
                    2: {
                        en: "Shadeless:",
                        zh: "无阴影：",
                        ja: "影なし",
                    },
                    3: {
                        en: "MatCap:",
                        zh: "MatCap：",
                        ja: "MatCap:",
                    },
                    4: {
                        en: "Wireframe:",
                        zh: "线框：",
                        ja: "ワイヤフレーム",
                    },
                    5: {
                        en: "Inspector:",
                        zh: "检视器：",
                        ja: "インスペクタ",
                    },
                },
            },
            inspector: {
                en: "Inspector",
                zh: "检视器",
                ja: "インスペクタ",
            },
            general: {
                title: {
                    en: "General",
                    zh: "一般",
                    ja: "一般事項",
                },
                fullscreen: {
                    en: "Fullscreen:",
                    zh: "全屏：",
                    ja: "フルスクリーン",
                },
            },
            lighting: {
                title: {
                    en: "Lighting",
                    zh: "照明",
                    ja: "照明",
                },
                1: {
                    title: {
                        en: "Rotate lighting:",
                        zh: "旋转照明：",
                        ja: "回転照明：",
                    },
                    text: {
                        1: {
                            en: "3-finger drag or",
                            zh: "三指拖动或",
                            ja: "3本指ドラッグまたは",
                        },
                        2: {
                            en: "Left Mouse Button",
                            zh: "鼠标左键",
                            ja: "左マウスボタン",
                        },
                    },
                },
                2: {
                    title: {
                        en: "Rotate env. only:",
                        zh: "仅旋转环境：",
                        ja: "回転環境オンリー： ",
                    },
                    text: {
                        en: "Left Mouse Button",
                        zh: "鼠标左键",
                        ja: "左マウスボタン",
                    },
                },
            },
            annotations: {
                title: {
                    en: "Annotations",
                    zh: "注释",
                    ja: "注釈",
                },
                or: {
                    en: "or",
                    zh: "或",
                    ja: "または",
                },
                pageup: {
                    en: "pageup",
                    zh: "pageup",
                    ja: "ページアップ",
                },
                pagedown: {
                    en: "pagedown",
                    zh: "pagedown",
                    ja: "ページダウン",
                },
                title1: {
                    en: "Previous:",
                    zh: "上一页：",
                    ja: "前へ：",
                },
                title2: {
                    en: "Next:",
                    zh: "下一页：",
                    ja: "次へ：",
                },
            },
            animations: {
                title: {
                    en: "Animations",
                    zh: "动画",
                    ja: "アニメーション",
                },
                title1: {
                    en: "Previous frame:",
                    zh: "上一帧：",
                    ja: "前のフレーム：",
                },
                title2: {
                    en: "Next frame:",
                    zh: "下一帧：",
                    ja: "次のフレーム：",
                },
                title3: {
                    en: "Go to time/frame:",
                    zh: "前往时间/帧：",
                    ja: "時間/フレームに移動：",
                },
                enter: {
                    en: "Enter",
                    zh: "Enter",
                    ja: "Enter",
                },
            },
            sound: {
                en: "Sound",
                zh: "声音",
                ja: "サウンド",
            },
            mute: {
                en: "Mute:",
                zh: "静音：",
                ja: "ミュート：",
            },
            firstPerson: {
                title: {
                    en: "First-person navigation",
                    zh: "第一人称导航",
                    ja: "一人称ナビゲーション",
                },
                title1: {
                    en: "Move:",
                    zh: "移动：",
                    ja: "ムーブ：",
                },
                title2: {
                    en: "Look around:",
                    zh: "环视：",
                    ja: "見回す：",
                },
                text2: {
                    en: "1-finger drag or Left Mouse Button",
                    zh: "单指拖动或鼠标左键",
                    ja: "1本指ドラッグまたは左マウスボタン",
                },
                title3: {
                    en: "Adjust speed:",
                    zh: "调整速度：",
                    ja: "スピード調整：",
                },
                mousewheel: {
                    en: "Mousewheel",
                    zh: "鼠标滚轮",
                    ja: "マウスホイール",
                },
            },
        },
        legal: {
            name: {
                en: "Legal",
                zh: "法律声明",
                ja: "法的事項",
            },
            text: {
                1: {
                    en: "Precision NanoSystems and the logo, NanoAssemblr, NanoAssemblr Blaze and Create Transformative Medicines, are registered trademarks of Precision NanoSystems or an affiliate doing business as Precision NanoSystems.",
                    zh: "Precision NanoSystems 和徽标 Nano Assemblr，NanoAssemblr Blaze 和 Create Transformative Medicines 是 Precision NanoSystems 或作为 Precision NanoSystems 开展业务的附属公司的注册商标。",
                    ja: "Precision NanoSystemsおよびそのロゴ、NanoAssemblr、NanoAssemblr BlazeおよびCreate Transformative Medicinesは、Precision NanoSystems またはPrecision NanoSystemsとして事業を行う関連会社の登録商標です",
                },
                2: {
                    en: "GenVoy, NxGen, Spark, Ignite, Ignite+ and Blaze+, are common law trademarks of Precision NanoSystems or an affiliate doing business as Precision NanoSystems.",
                    zh: "GenVoy、NxGen、Spark、Ignite、Ignite+ 和 Blaze+ 是 Precision NanoSystems 或作为 Precision NanoSystems 开展业务的附属公司的普通法商标。",
                    ja: "GenVoy、NxGen、Spark、Ignite、Ignite+およびBlaze+は、Precision NanoSystems または Precision NanoSystemsとして事業を行う関連会社のコモンロー商標です。",
                },
                3: {
                    en: "For local office contact information, visit:",
                    zh: "与当地办公室的相关联系信息，请访问：",
                    ja: "現地オフィス連絡先についてはこちらを参照：",
                },
                4: {
                    en: "© 2023 Precision NanoSystems. All Rights Reserved.",
                    zh: "© 2023 Precision NanoSystems. 版权所有。",
                    ja: "© 2023 Precision NanoSystems.禁無断転載。",
                },
            },
        },
    },
};
