import React from "react";
import { Title, Text } from "../dynamic.components";
import { useStore } from "src/store";
import { translationService } from "src/services/translation.service";

export const Pumps: React.FC = () => {
    const { activeSubLink, locale } = useStore();

    const mainText = translationService.translate("sidebar.pumps.main", locale);

    return (
        <>
            <Title title={activeSubLink.text[locale]} />

            <div className="c-sidebar__body">
                <Text text={mainText} />
            </div>
        </>
    );
};
