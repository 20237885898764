import React from "react";
import { Title, Text, Spacer, List } from "../dynamic.components";
import { useStore } from "src/store";
import { translationService } from "src/services/translation.service";

export const Dilution: React.FC = () => {
    const { activeSubLink, locale } = useStore();

    const mainText = translationService.translate(
        "sidebar.dilution.main",
        locale
    );
    const listItem1 = translationService.translate(
        "sidebar.dilution.list.1",
        locale
    );

    return (
        <>
            <Title title={activeSubLink.text[locale]} />

            <div className="c-sidebar__body">
                <Text text={mainText} />
                <Spacer />
                <List listArray={[listItem1]} />
            </div>
        </>
    );
};
