import { useStore } from "src/store";

const DropdownToggle = () => {
    const { toggleDropdown, dropdown } = useStore();

    return (
        <div className="c-cytiva__hamburger-menu">
            <input
                id="toggle-navlink"
                className="c-cytiva__hamburger-menu__input"
                type={"checkbox"}
                onChange={toggleDropdown}
            />
            <label
                className="c-cytiva__hamburger-menu__label"
                htmlFor="toggle-navlink"
            >
                <span
                    className={`c-cytiva__hamburger-menu__icon ${
                        dropdown ? "top-line--active" : "top-line"
                    }`}
                ></span>
                <span
                    className={`c-cytiva__hamburger-menu__icon ${
                        dropdown ? "mid-line--active" : "mid-line"
                    }`}
                ></span>
                <span
                    className={`c-cytiva__hamburger-menu__icon ${
                        dropdown ? "bottom-line--active" : "bottom-line"
                    }`}
                ></span>
            </label>
        </div>
    );
};
export default DropdownToggle;
