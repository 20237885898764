import React, { useEffect } from "react";
import { useStore } from "src/store";
import { Title, Text, List, Spacer } from "../dynamic.components";

import { setAnimationFrame } from "src/services/animations";
import { componentAnimationReadyToPlay } from "src/utils/helper";
import { StackLight } from "src/types/index.d";
import { switchLamp } from "src/services/materials";
import { translationService } from "src/services/translation.service";

export const FlowKit: React.FC = (): JSX.Element => {
    const { activeSubLink, stackLights, toggleLamp, sidebarStatus, locale } =
        useStore();
    const onClickLamp = (stack: StackLight) => {
        toggleLamp(stack.material);
        switchLamp(stack.material);
    };

    useEffect(() => {
        if (componentAnimationReadyToPlay("1-3")) {
            activeSubLink.staticFrameWithHotspot &&
                setAnimationFrame(activeSubLink.staticFrameWithHotspot[0]);
        }
    }, [sidebarStatus]);

    const singleUseText = translationService.translate(
        "sidebar.singleUse",
        locale
    );
    const mainText = translationService.translate(
        "sidebar.flowKit.main",
        locale
    );
    const subText = translationService.translate("sidebar.flowKit.sub", locale);
    const listItem1 = translationService.translate(
        "sidebar.flowKit.list.1",
        locale
    );
    const listItem2 = translationService.translate(
        "sidebar.flowKit.list.2",
        locale
    );
    const listItem3 = translationService.translate(
        "sidebar.flowKit.list.3",
        locale
    );
    const listItem4 = translationService.translate(
        "sidebar.flowKit.list.4",
        locale
    );
    const listItem5 = translationService.translate(
        "sidebar.flowKit.list.5",
        locale
    );

    const title = translationService.translate("single_nxgen_flowkit", locale);

    return (
        <>
            <Title title={title} />

            <div className="c-sidebar__body">
                <Text text={mainText} />
                <Text text={subText} />
                <Spacer x={2} />
                <List
                    listArray={[
                        listItem1,
                        listItem2,
                        listItem3,
                        listItem4,
                        listItem5,
                    ]}
                />
                <Spacer x={3} />
                <div className="c-sidebar__stack-lights">
                    {stackLights.length
                        ? stackLights.map((stack) => (
                              <div
                                  className="stack-light__row"
                                  key={stack.name.en}
                                  style={{
                                      display: "flex",
                                      alignItems: "center",
                                  }}
                              >
                                  <button
                                      className={`button button--medium  button--fixwidth`}
                                      style={{
                                          backgroundColor: stack.lightOff,
                                          filter: stack.status
                                              ? "brightness(150%)"
                                              : "",
                                      }}
                                      onClick={() => onClickLamp(stack)}
                                  ></button>
                                  <p
                                      className="stack-light__name"
                                      style={{
                                          color: stack.lightOff,
                                          filter: stack.status
                                              ? "brightness(150%)"
                                              : "",
                                          cursor: "pointer",
                                      }}
                                      onClick={() => onClickLamp(stack)}
                                  >
                                      {stack.name[locale]}
                                  </p>
                              </div>
                          ))
                        : ""}
                </div>
            </div>
        </>
    );
};
