import React from "react";
import { useStore } from "src/store";
import cytiva_svg from "src/assets/svg/cytiva.svg";
import { translationService } from "src/services/translation.service";

const Help = ({}) => {
    const { closeOverlay, locale } = useStore();

    const orbitTitle = translationService.translate(
        "help.top.left.title",
        locale
    );
    const orbitText = translationService.translate(
        "help.top.left.text",
        locale
    );
    const zoomTitle = translationService.translate(
        "help.top.center.title",
        locale
    );
    const zoomText = translationService.translate(
        "help.top.center.text",
        locale
    );
    const panTitle = translationService.translate(
        "help.top.right.title",
        locale
    );
    const panText = translationService.translate("help.top.right.text", locale);

    const orbitNavigationTitle = translationService.translate(
        "help.orbit.title",
        locale
    );
    const orbitCameraTitle = translationService.translate(
        "help.orbit.camera.title",
        locale
    );
    const orbitCameraText = translationService.translate(
        "help.orbit.camera.text",
        locale
    );
    const orbitPanTitle = translationService.translate(
        "help.orbit.pan.title",
        locale
    );
    const orbitPanText1 = translationService.translate(
        "help.orbit.pan.text.1",
        locale
    );
    const orbitPanText2 = translationService.translate(
        "help.orbit.pan.text.2",
        locale
    );
    const orbitPanText3 = translationService.translate(
        "help.orbit.pan.text.3",
        locale
    );
    const orbitZoomObjTitle = translationService.translate(
        "help.orbit.zoomObj.title",
        locale
    );
    const orbitZoomObjText = translationService.translate(
        "help.orbit.zoomObj.text",
        locale
    );
    const orbitZoomOutTitle = translationService.translate(
        "help.orbit.zoomOut.title",
        locale
    );
    const orbitZoomOutText = translationService.translate(
        "help.orbit.zoomOut.text",
        locale
    );

    const orbitZoomText1 = translationService.translate(
        "help.orbit.zoom.text.1",
        locale
    );
    const orbitZoomText2 = translationService.translate(
        "help.orbit.zoom.text.2",
        locale
    );
    const orbitZoomText3 = translationService.translate(
        "help.orbit.zoom.text.3",
        locale
    );

    const renderingTitle = translationService.translate(
        "help.rendering.title",
        locale
    );

    const rendering1 = translationService.translate(
        "help.rendering.list.1",
        locale
    );
    const rendering2 = translationService.translate(
        "help.rendering.list.2",
        locale
    );
    const rendering3 = translationService.translate(
        "help.rendering.list.3",
        locale
    );
    const rendering4 = translationService.translate(
        "help.rendering.list.4",
        locale
    );
    const rendering5 = translationService.translate(
        "help.rendering.list.5",
        locale
    );

    const inspectorText = translationService.translate(
        "help.inspector",
        locale
    );
    const generalText = translationService.translate(
        "help.general.title",
        locale
    );
    const fullscreenText = translationService.translate(
        "help.general.fullscreen",
        locale
    );
    const lightingTitle = translationService.translate(
        "help.lighting.title",
        locale
    );
    const lightingList1Title = translationService.translate(
        "help.lighting.1.title",
        locale
    );
    const lightingList1Text1 = translationService.translate(
        "help.lighting.1.text.1",
        locale
    );
    const lightingList1Text2 = translationService.translate(
        "help.lighting.1.text.2",
        locale
    );
    const lightingList2Title = translationService.translate(
        "help.lighting.2.title",
        locale
    );
    const lightingList2Text = translationService.translate(
        "help.lighting.2.text",
        locale
    );
    // Annotations
    const annotationsTitle = translationService.translate(
        "help.annotations.title",
        locale
    );
    const annotationsTitle1 = translationService.translate(
        "help.annotations.title1",
        locale
    );
    const annotationsTitle2 = translationService.translate(
        "help.annotations.title2",
        locale
    );
    const pageupText = translationService.translate(
        "help.annotations.pageup",
        locale
    );
    const pagedownText = translationService.translate(
        "help.annotations.pagedown",
        locale
    );
    const orText = translationService.translate("help.annotations.or", locale);
    const enterText = translationService.translate(
        "help.animations.enter",
        locale
    );
    const mousewheelText = translationService.translate(
        "help.firstPerson.mousewheel",
        locale
    );
    const animationsTitle = translationService.translate(
        "help.animations.title",
        locale
    );
    const animationsTitle1 = translationService.translate(
        "help.animations.title1",
        locale
    );
    const animationsTitle2 = translationService.translate(
        "help.animations.title2",
        locale
    );
    const animationsTitle3 = translationService.translate(
        "help.animations.title3",
        locale
    );
    const firstPersonTitle = translationService.translate(
        "help.firstPerson.title",
        locale
    );
    const soundText = translationService.translate("help.sound", locale);
    const muteText = translationService.translate("help.mute", locale);
    const firstPersonTitle1 = translationService.translate(
        "help.firstPerson.title1",
        locale
    );
    const firstPersonTitle2 = translationService.translate(
        "help.firstPerson.title2",
        locale
    );
    const firstPersonText2 = translationService.translate(
        "help.firstPerson.text2",
        locale
    );
    const firstPersonTitle3 = translationService.translate(
        "help.firstPerson.title3",
        locale
    );

    return (
        <>
            <div
                className="c-cytiva__help-section"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="c-cytiva__help-top">
                    <img
                        className="c-cytiva__help-top__logo"
                        src="favicon.ico"
                        width="40"
                        alt="Cytiva logo"
                    />
                    <button
                        className="c-cytiva__help-top__close"
                        onClick={closeOverlay}
                    >
                        X
                    </button>
                </div>
                <div className="c-cytiva__help-bottom">
                    <div className="c-cytiva__help-navigation c-cytiva__bottom-section">
                        <div className="c-cytiva__help-navigation__section">
                            <svg className="c-cytiva__help-svg-icon">
                                <use xlinkHref={`${cytiva_svg}#orbit-around`} />
                            </svg>
                            <div className="c-cytiva__help-navigation__content">
                                <p className="c-cytiva__subtext">
                                    {orbitTitle}
                                </p>
                                <p className="c-cytiva__text">{orbitText}</p>
                            </div>
                        </div>
                        <div className="c-cytiva__help-navigation__section">
                            <svg
                                className="c-cytiva__help-svg-icon"
                                aria-hidden="true"
                            >
                                <use xlinkHref={`${cytiva_svg}#zoom-in`} />
                            </svg>
                            <div className="c-cytiva__help-navigation__content">
                                <p className="c-cytiva__subtext">{zoomTitle}</p>
                                <p className="c-cytiva__text">{zoomText}</p>
                            </div>
                        </div>
                        <div className="c-cytiva__help-navigation__section">
                            <svg
                                className="c-cytiva__help-svg-icon"
                                aria-hidden="true"
                            >
                                <use xlinkHref={`${cytiva_svg}#pan`} />
                            </svg>
                            <div className="c-cytiva__help-navigation__content">
                                <p className="c-cytiva__subtext">{panTitle}</p>
                                <p className="c-cytiva__text">{panText}</p>
                            </div>
                        </div>
                    </div>
                    <div className="c-cytiva__bottom-section">
                        <p className="c-cytiva__subtext">
                            {orbitNavigationTitle}
                        </p>
                        <span className="c-cytiva__divider"></span>
                        <div className="c-cytiva__text">
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {orbitCameraTitle}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    {orbitCameraText}
                                </p>
                            </div>
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {orbitPanTitle}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    {orbitPanText1}{" "}
                                    <kbd className="c-cytiva__kbd">
                                        {orbitPanText2}
                                    </kbd>{" "}
                                    {orbitPanText3}
                                </p>
                            </div>
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {orbitZoomObjTitle}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    {orbitZoomObjText}
                                </p>
                            </div>
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {orbitZoomOutTitle}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    {orbitZoomOutText}
                                </p>
                            </div>
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {zoomTitle}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    {orbitZoomText1}{" "}
                                    <kbd className="c-cytiva__kbd">
                                        {orbitZoomText2}
                                    </kbd>{" "}
                                    {orbitZoomText3}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="c-cytiva__bottom-section">
                        <p className="c-cytiva__help-bottom__title c-cytiva__subtext">
                            {renderingTitle}
                        </p>
                        <span className="c-cytiva__divider"></span>
                        <div className="c-cytiva__text">
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {rendering1}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    <kbd className="c-cytiva__kbd">1</kbd>
                                </p>
                            </div>
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {rendering2}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    <kbd className="c-cytiva__kbd">2</kbd>
                                </p>
                            </div>
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {rendering3}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    <kbd className="c-cytiva__kbd">3</kbd>
                                </p>
                            </div>
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {rendering4}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    <kbd className="c-cytiva__kbd">5</kbd>
                                </p>
                            </div>
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {rendering5}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    <kbd className="c-cytiva__kbd">i</kbd>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="c-cytiva__bottom-section">
                        <p className="c-cytiva__help-bottom__title c-cytiva__subtext">
                            {inspectorText}
                        </p>
                        <span className="c-cytiva__divider"></span>
                    </div>

                    <div className="c-cytiva__bottom-section">
                        <p className="c-cytiva__subtext">{generalText}</p>
                        <span className="c-cytiva__divider"></span>
                        <div className="c-cytiva__text">
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {fullscreenText}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    <kbd className="c-cytiva__kbd">f</kbd>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="c-cytiva__bottom-section">
                        <p className="c-cytiva__subtext">{lightingTitle}</p>
                        <span className="c-cytiva__divider"></span>
                        <div className="c-cytiva__text">
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {lightingList1Title}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    {lightingList1Text1}{" "}
                                    <kbd className="c-cytiva__kbd">ALT</kbd> +{" "}
                                    {lightingList1Text2}
                                </p>
                            </div>
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {lightingList2Title}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    <kbd className="c-cytiva__kbd">ALT</kbd> +{" "}
                                    <kbd className="c-cytiva__kbd">SHIFT</kbd> +{" "}
                                    {lightingList2Text}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="annotation-shortcuts">
                        <p className="c-cytiva__help-bottom__title c-cytiva__subtext">
                            {annotationsTitle}
                        </p>
                        <span className="c-cytiva__divider"></span>
                        <div className="c-cytiva__text">
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {annotationsTitle1}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    <kbd className="c-cytiva__kbd">j</kbd>{" "}
                                    {orText}{" "}
                                    <kbd className="c-cytiva__kbd">
                                        {pageupText}
                                    </kbd>
                                </p>
                            </div>
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {annotationsTitle2}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    <kbd className="c-cytiva__kbd">k</kbd>{" "}
                                    {orText}{" "}
                                    <kbd className="c-cytiva__kbd">
                                        {pagedownText}
                                    </kbd>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="c-cytiva__bottom-section">
                        <p className="c-cytiva__subtext">{animationsTitle}</p>
                        <span className="c-cytiva__divider"></span>
                        <div className="c-cytiva__text">
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {animationsTitle1}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    <kbd className="c-cytiva__kbd">b</kbd>{" "}
                                    {orText}{" "}
                                    <kbd className="c-cytiva__kbd">ctrl+b</kbd>{" "}
                                    {orText}{" "}
                                    <kbd className="c-cytiva__kbd">shift+b</kbd>
                                </p>
                            </div>
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {animationsTitle2}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    <kbd className="c-cytiva__kbd">n</kbd>{" "}
                                    {orText}{" "}
                                    <kbd className="c-cytiva__kbd">ctrl+n</kbd>{" "}
                                    {orText}{" "}
                                    <kbd className="c-cytiva__kbd">shift+n</kbd>
                                </p>
                            </div>
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {animationsTitle3}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    <kbd className="c-cytiva__kbd">
                                        {enterText}
                                    </kbd>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="c-cytiva__bottom-section">
                        <p className="c-cytiva__subtext">{soundText}</p>
                        <span className="c-cytiva__divider"></span>
                        <div className="c-cytiva__text">
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {muteText}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    <kbd className="c-cytiva__kbd">m</kbd>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div>
                        <span className="c-cytiva__subtext">
                            {firstPersonTitle}
                        </span>
                        <span className="c-cytiva__divider"></span>
                        <div className="c-cytiva__text">
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {firstPersonTitle1}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    <kbd className="c-cytiva__kbd">↑</kbd>,
                                    <kbd className="c-cytiva__kbd">←</kbd>,
                                    <kbd className="c-cytiva__kbd">↓</kbd>,
                                    <kbd className="c-cytiva__kbd">→</kbd> (
                                    {orText}{" "}
                                    <kbd className="c-cytiva__kbd">W</kbd>,
                                    <kbd className="c-cytiva__kbd">A</kbd>,
                                    <kbd className="c-cytiva__kbd">S</kbd>,
                                    <kbd className="c-cytiva__kbd">D</kbd>)
                                </p>
                            </div>
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {firstPersonTitle2}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    {firstPersonText2}
                                </p>
                            </div>
                            <div className="c-cytiva__help-row">
                                <p className="c-cytiva__help-row__left">
                                    {firstPersonTitle3}
                                </p>
                                <p className="c-cytiva__help-row__right">
                                    {mousewheelText}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Help;
