import { multiHotspotComponentId } from "src/constants";
import { useStore } from "../store";
import {
    Animation,
    Material,
    SidebarVisibility,
    TreeDModel,
} from "../types/index.d";
import { playHotspot } from "./annotations";
import { getMaterialByName } from "./materials";
import {
    changeMaterialEmissionFactor,
    changeMaterialOpacity,
    changeMaterialOpacityByName,
    changeMaterialVisibility,
} from "./materials-textures";

export const storeAnimations = () => {
    const api = useStore.getState().apiref;
    api.getAnimations((err: any, animations: Animation[]) => {
        if (!err) {
            useStore.setState({ animations: animations });
        }
    });
};

export const getAnimation = (index: number) => {
    const { activeSubLink, animations } = useStore.getState();
    return animations.find((animation) => {
        if (activeSubLink.animations) {
            return animation[1] === activeSubLink.animations[index];
        }
    });
};

export const playAnimation = () => {
    const { apiref, activeSubLink, setAnimationStopped } = useStore.getState();
    const startTime = activeSubLink.animationSequences?.[0][0];
    const endTime = activeSubLink.animationSequences?.[0][1];
    const duration = endTime && startTime && endTime - startTime;

    setAnimationStopped(false);

    const sceneAnimation = getAnimation(0);

    const sceneAnimationId = sceneAnimation && sceneAnimation[0];

    // apiref.setCurrentAnimationByUID(sceneAnimationId);
    apiref.pause(() => {
        apiref.seekTo(startTime, () => {
            apiref.play();
            useStore.setState({ animationIsPlaying: true });
            setTimeout(() => {
                apiref.pause(() => {});
                useStore.setState({ animationIsPlaying: false });
            }, duration && duration * 1000);
        });
    });
};

// export const playCustomAnimation = () => {
//   const { apiref, activeSubLink, setAnimationStopped } = useStore.getState();
//   const firstSeqTime = activeSubLink.animationSequences?.[0];
//   const secondSeqTime = activeSubLink.animationSequences?.[1];
//   const thirdSeqTime = activeSubLink.animationSequences?.[2];
//   const fourthSeqTime = activeSubLink.animationSequences?.[3];

//   const firstAnnotaion = activeSubLink.annotations?.[0];
//   const secondAnnotaion = activeSubLink.annotations?.[1];

//   const animationDuration =
//     firstSeqTime && fourthSeqTime && fourthSeqTime - firstSeqTime;

//   const secondHotspot =
//     firstSeqTime && secondSeqTime && secondSeqTime - firstSeqTime;

//   setAnimationStopped(false);
//   firstAnnotaion && playHotspot(firstAnnotaion);

//   apiref.pause(() => {
//     apiref.seekTo(firstSeqTime, () => {
//       apiref.play();
//       useStore.setState({ animationIsPlaying: true });

//       setTimeout(() => {
//         secondAnnotaion && playHotspot(secondAnnotaion);
//       }, secondHotspot && secondHotspot * 1000);

//       setTimeout(() => {
//         apiref.pause(() => {
//           useStore.setState({ animationIsPlaying: false });
//         });
//       }, animationDuration && animationDuration * 1000);
//     });
//   });
// };

export const playOneAnimation = (
    annotation?: string,
    animationSequence?: number[],
    isFirst?: boolean,
    isLast?: boolean
) => {
    return new Promise((resolve, reject) => {
        const { apiref, setAnimationStopped } = useStore.getState();
        const firstSeqTime = animationSequence?.[0];
        const secondSeqTime = animationSequence?.[1];

        const animationDuration =
            firstSeqTime && secondSeqTime && secondSeqTime - firstSeqTime;

        isFirst && setAnimationStopped(false);

        annotation && playHotspot(annotation);

        apiref.pause(() => {
            apiref.seekTo(firstSeqTime, () => {
                apiref.play();
                useStore.setState({ animationIsPlaying: true });

                setTimeout(() => {
                    apiref.pause(() => {
                        isLast &&
                            useStore.setState({ animationIsPlaying: false });
                        resolve(true);
                    });
                }, animationDuration && animationDuration * 1000);
            });
        });
    });
};

export const playCustomAnimation = () => {
    const { activeSubLink } = useStore.getState();

    if (
        activeSubLink.animationSequences &&
        activeSubLink.animationSequences.length
    ) {
        for (
            let i = 0, p = Promise.resolve();
            i < activeSubLink.animationSequences.length;
            i++
        ) {
            const isLast = activeSubLink.animationSequences?.length === i + 1;
            const isFirst = i === 0;

            p = p
                .then(() =>
                    playOneAnimation(
                        activeSubLink.annotations?.[i],
                        [
                            //@ts-ignore
                            activeSubLink.animationSequences[i][0],
                            //@ts-ignore
                            activeSubLink.animationSequences[i][1],
                        ],
                        isFirst,
                        isLast
                    )
                )
                .then(() => {
                    // console.log(i + 1, "Animation played")
                });
        }
    }
};

export const playAnimationByModel = (
    treeDModel: TreeDModel = TreeDModel.one_S_Plus
) => {
    // console.log("Animation is start playing");
    const { apiref, activeSubLink, setAnimationStopped } = useStore.getState();
    const startTime =
        treeDModel !== TreeDModel.one_K
            ? activeSubLink.animationSequences?.[0][0]
            : activeSubLink.animationSequences?.[0][2];
    const endTime =
        treeDModel !== TreeDModel.one_K
            ? activeSubLink.animationSequences?.[1][1]
            : activeSubLink.animationSequences?.[1][3];
    const duration = endTime && startTime && endTime - startTime;

    setAnimationStopped(false);

    const sceneAnimation = getAnimation(0);

    const sceneAnimationId = sceneAnimation && sceneAnimation[0];

    // Blink lamp
    activeSubLink.changeMaterials &&
        blinkingLamp(activeSubLink.changeMaterials[3]);
    // apiref.setCurrentAnimationByUID(sceneAnimationId);
    apiref.pause(() => {
        apiref.seekTo(startTime, () => {
            if (activeSubLink.id === "3-2") {
                setTimeout(() => {
                    activeSubLink.annotations &&
                        playHotspot(activeSubLink.annotations[0]);
                }, 2000);
            }
            if (activeSubLink.id === "3-4") {
                setTimeout(() => {
                    activeSubLink.annotations &&
                        playHotspot(activeSubLink.annotations[0]);
                }, 2000);
                setTimeout(() => {
                    activeSubLink.annotations &&
                        playHotspot(activeSubLink.annotations[1]);
                }, 5000);
                setTimeout(() => {
                    activeSubLink.annotations &&
                        playHotspot(activeSubLink.annotations[2]);
                }, 7500);
            }

            apiref.play();
            useStore.setState({ animationIsPlaying: true });
            setTimeout(() => {
                apiref.pause(() => {});
                useStore.setState({ animationIsPlaying: false });
            }, duration && duration * 1000);
        });
    });
};

export const blinkingLamp = (matName: string) => {
    const { activeSubLink } = useStore.getState();
    const blinkMaterial =
        activeSubLink.changeMaterials && getMaterialByName(matName);

    let timePassed = 0;
    const timeInterval = 200;
    let emissionValue = -0.5;
    if (blinkMaterial) {
        const subscriber = setInterval(() => {
            timePassed += timeInterval;
            changeMaterialEmissionFactor(blinkMaterial, emissionValue);
            if (emissionValue >= 2) emissionValue = -0.5;
            emissionValue += 1;
            if (timePassed >= 3500) clearInterval(subscriber);
        }, timeInterval);
    }
};

export const setAnimationFrame = ({
    annotation,
    frame,
}: {
    annotation: string;
    frame: number;
}) => {
    const { apiref } = useStore.getState();
    playHotspot(annotation);
    apiref.pause(() => {
        apiref.seekTo(frame);
    });
};

export const resetAnimationFrame = () => {
    const { apiref } = useStore.getState();

    apiref.pause(() => {
        apiref.seekTo(0);
    });
};

export const switchToModel = (model: TreeDModel) => {
    const { apiref } = useStore.getState();

    if (model === TreeDModel.one_K) {
        apiref.pause(() => {
            apiref.seekTo(51, () => {});
        });
    }

    if (model === TreeDModel.one_K_Plus) {
        apiref.pause(() => {
            apiref.seekTo(12, () => {
                changeMaterialOpacityByName("UNO_label_1Kplus", 1);
                changeMaterialOpacityByName("UNO_label_1Splus", 0);
            });
        });
    }
};
