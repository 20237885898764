import { useStore } from "src/store";
import Overlay from "./Overlay";
import { translationService } from "src/services/translation.service";

const HelpLegalWrapper = () => {
    const { viewIsReady, toggleOverlay, overlays, locale } = useStore();

    const helpText = translationService.translate("help.name", locale);
    const legalText = translationService.translate("legal.name", locale);

    return (
        <>
            {viewIsReady && (
                <>
                    <button
                        className={`button button--medium button--tertiary c-cytiva__trademark-icon`}
                        onClick={() => toggleOverlay("trademark")}
                    >
                        {legalText}
                    </button>

                    <button
                        className={`button button--medium button--tertiary c-cytiva__help-icon`}
                        onClick={() => toggleOverlay("help")}
                    >
                        {helpText}
                    </button>
                </>
            )}
            {(overlays.help || overlays.trademark) && <Overlay />}
        </>
    );
};
export default HelpLegalWrapper;
