import React from "react";
import { Title, Text, Spacer, List } from "../dynamic.components";
import { useStore } from "src/store";
import { translationService } from "src/services/translation.service";

export const Automation: React.FC = () => {
    const { activeSubLink, locale } = useStore();

    const mainText = translationService.translate(
        "sidebar.automation.main",
        locale
    );
    const listItem1 = translationService.translate(
        "sidebar.automation.list.1",
        locale
    );
    const listItem2 = translationService.translate(
        "sidebar.automation.list.2",
        locale
    );

    return (
        <>
            <Title title={activeSubLink.text[locale]} />

            <div className="c-sidebar__body">
                <Text text={mainText} />
                <Spacer x={2} />
                <List listArray={[listItem1, listItem2]} />
                <Spacer />
            </div>
        </>
    );
};
