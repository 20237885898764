import { useStore } from "src/store";
import { translationsData } from "../db/translations";
import { Locale } from "src/types";

class TranslationService {
    public locale: Locale;
    public translations: Object;

    constructor() {
        this.translations = {};
        this.getTranslations();
        this.locale = useStore.getState().locale;
    }

    getTranslations() {
        this.translations = translationsData.translations;
    }

    formatString(str: string, ...val: string[]) {
        for (let index = 0; index < val.length; index++) {
            str = str.replace(`{${index}}`, val[index]);
        }
        return str;
    }

    public translate = (
        keys: string,
        locale: string,
        ...placeholders: string[]
    ): string => {
        const translatedText: any = keys
            .concat("." + locale)
            .split(".")
            .reduce((current: any, key) => current[key], this.translations);

        return this.formatString(translatedText, ...placeholders);
    };
}

export const translationService = new TranslationService();
