import React from "react";
import { Title, Text, List, Spacer, Image, Video } from "../dynamic.components";
import { useStore } from "src/store";
import cartridge_pg_film from "src/assets/video/12095_Enfield_cartridge_10sek.mp4";
import { translationService } from "src/services/translation.service";

export const Cartridge: React.FC = () => {
    const { activeSubLink, locale } = useStore();

    const singleUseText = translationService.translate(
        "sidebar.singleUse",
        locale
    );
    const mainText = translationService.translate(
        "sidebar.cartridge.main",
        locale
    );
    const listItem1 = translationService.translate(
        "sidebar.cartridge.list.1",
        locale
    );
    const listItem2 = translationService.translate(
        "sidebar.cartridge.list.2",
        locale
    );

    const title = translationService.translate(
        "single_nxgen_cartridge",
        locale
    );

    return (
        <>
            <Title title={title} />

            <div className="c-sidebar__body">
                <Text text={mainText} />
                <Spacer x={2} />
                <List listArray={[listItem1, listItem2]} />
                <Spacer x={2} />
                <Video src={cartridge_pg_film} />
            </div>
        </>
    );
};
