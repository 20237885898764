import globe_svg from "src/assets/svg/globe-cyan.svg";
import arrow_up from "src/assets/svg/arrow-up-cyan.svg";
import arrow_down from "src/assets/svg/arrow-down-cyan.svg";
import { useStore } from "src/store";
import { Locale } from "src/types/index.d";
import { useEffect, useState } from "react";
import { translationService } from "src/services/translation.service";

const Language = () => {
    const [dropdown, setDropdown] = useState(false);
    const { locale, changeLocale } = useStore();

    useEffect(() => {
        document.addEventListener("click", closeDropdown);

        return () => {
            document.removeEventListener("click", closeDropdown);
        };
    }, []);

    const closeDropdown = () => {
        setDropdown(false);
    };

    const languageName = (locale: Locale) => {
        switch (locale) {
            case Locale.en:
                return "English";
            case Locale.zh:
                return "中文";
            case Locale.ja:
                return "日本語";
            default:
                return "";
        }
    };

    const handleChangeLang = (locale: Locale) => {
        closeDropdown();
        changeLocale(locale);
        document.title = translationService.translate("title", locale);
    };

    return (
        <div className="cytiva-language">
            <div
                className="cytiva-language__top"
                onClick={(e) => {
                    setDropdown(!dropdown);
                    e.stopPropagation();
                }}
            >
                <div className="cytiva-language__top-left">
                    <p className="lang-text">Language</p>
                    <div className="selected-lang">
                        <img src={globe_svg} alt="" />
                        <p>{languageName(locale)}</p>
                    </div>
                </div>
                <div className="cytiva-language__top-right">
                    {dropdown ? (
                        <img src={arrow_up} alt="" />
                    ) : (
                        <img src={arrow_down} alt="" />
                    )}
                </div>
            </div>

            {dropdown && (
                <div className="cytiva-language__dropdown">
                    <ul className="language-list">
                        {Object.values(Locale).map((locale: Locale) => (
                            <li
                                className="language-list__item"
                                key={locale}
                                onClick={() => handleChangeLang(locale)}
                            >
                                <p>{languageName(locale)}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};
export default Language;
