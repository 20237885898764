import { CustomLink, Locale } from "../types/index.d";

export enum SubLinkId {
    automation = "1-1",
    cartridge = "1-2",
    flowKit = "1-3",
    flow = "1-4",
    pumps = "1-5",
    rating = "1-6",
    dilution = "1-7",
}

export const links: CustomLink[] = [
    {
        id: "1",
        title: "HMI",
        hasSublinks: true,
        annotations: ["HMI"],
        subLinks: [
            {
                id: "1-1",
                text: {
                    [Locale.en]: "Automation",
                    [Locale.zh]: "自动化",
                    [Locale.ja]: "オートメーション",
                },
                annotations: ["Automation"],
            },
            {
                id: "1-2",
                text: {
                    [Locale.en]: "NxGen™ cartridge",
                    [Locale.zh]: "NxGen™ 芯片",
                    [Locale.ja]: "NxGen™ カートリッジ",
                },
                annotations: ["Cartridge"],
            },
            {
                id: "1-3",
                text: {
                    [Locale.en]: "NxGen™ flow kit",
                    [Locale.zh]: "NxGen™ 流路套件",
                    [Locale.ja]: "NxGen™ フローキット",
                },
                annotations: ["Flowkit"],
                animations: ["Scene"],
                staticFrameWithHotspot: [{ annotation: "Flowkit", frame: 1 }],
            },
            {
                id: "1-4",
                text: {
                    [Locale.en]: "Flow meters",
                    [Locale.zh]: "流量计",
                    [Locale.ja]: "流量計",
                },
                annotations: ["Flow"],
                extras: { annotations: ["Coriolis"] },
            },
            {
                id: "1-5",
                text: {
                    [Locale.en]: "Centrifugal pumps",
                    [Locale.zh]: "离心泵",
                    [Locale.ja]: "遠心ポンプ",
                },
                annotations: ["Pumps"],
            },
            {
                id: "1-6",
                text: {
                    [Locale.en]: "Hazardous location rated",
                    [Locale.zh]: "通过危险场所认证",
                    [Locale.ja]: "防爆構造",
                },
                annotations: ["Rating"],
            },
            {
                id: "1-7",
                text: {
                    [Locale.en]: "In-line dilution",
                    [Locale.zh]: "在线稀释",
                    [Locale.ja]: "インライン希釈",
                },
                annotations: ["Dilution"],
            },
        ],
    },
];
